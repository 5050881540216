import { Pngs } from "res";
import { ExplainBlock } from "./types";

export const explainTitleSubtitle = {
  explain: {
    title: "We’ll curate your reading list.",
    subtitle: "Tell us what books you love. We’ll take care of the rest.",
  },
  ratings: {
    title: "Real Stories from Satisfied Customers",
    subtitle: "See how our book recommendations are making an impact.",
  },
};

export const explains: ExplainBlock[] = [
  {
    index: 0,
    title: "Easy to Use",
    explain:
      "Add the books on your shelf quickly and easily, and start getting amazing recommendations fast.",
    icon: Pngs.ExplainIcons.explain.easyToUseIcon,
  },
  {
    index: 1,
    title: "Super-Accurate",
    explain:
      "All our book recommendations are custom-tailored to the books you love. Get access to more of what you like. ",
    icon: Pngs.ExplainIcons.explain.superAccurateIcon,
  },
  {
    index: 2,
    title: "100% Free",
    explain:
      "This app is free, and we don’t have any subscription or premium plans. We’ll never sell your data to anyone, either.",
    icon: Pngs.ExplainIcons.explain.freeIcon,
  },
];

export const ratings: ExplainBlock[] = [
  {
    index: 0,
    title: "Noah S.",
    subtitle: "Book Club Founder",
    explain:
      "I read a bunch of niche sci-fi and I usually get recommendations from my friends, but by now we’ve mostly read the same stuff. SnapShelf is a great way to find new awesome books for my book club.",
    icon: Pngs.ExplainIcons.ratings.noahIcon,
    stars: 5,
  },
  {
    index: 1,
    title: "Bea D.",
    subtitle: "New Mother",
    explain:
      "My 3-year-old son reads ~20 books a day, and I have pretty specific preferences on the books that I like to read with him. SnapShelf has been an amazing way to find new books he loves. Best part: it’s fast; it doesn’t require arduous research.",
    icon: Pngs.ExplainIcons.ratings.beaIcon,
    stars: 4,
  },
  {
    index: 2,
    title: "Austin H.",
    subtitle: "Avid Reader",
    explain:
      "This app is like having your own personal butler for books. It’s basically a curator that knows all the books I love to read. Can’t believe it’s free.",
    icon: Pngs.ExplainIcons.ratings.austinIcon,
    stars: 5,
  },
];
